<template>
  <b-button class="is-flex px-5" :type="buttonType" rounded>
    <b-icon
      v-if="icon"
      icon-pack="fa"
      :icon="icon"
      size="is-small"
      class="is-inline-block mr-1"
    />
    <span>{{ label }}</span>
  </b-button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonType() {
      return this.selected ? "is-blue" : "";
    },
  },
};
</script>
